import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-fluid" }
const _hoisted_2 = { class: "p-d-flex p-justify-center" }
const _hoisted_3 = { class: "p-field" }
const _hoisted_4 = { for: "reference" }
const _hoisted_5 = { class: "p-field" }
const _hoisted_6 = { for: "name" }
const _hoisted_7 = { class: "p-field" }
const _hoisted_8 = { for: "category" }
const _hoisted_9 = { class: "p-field" }
const _hoisted_10 = { for: "quantity" }
const _hoisted_11 = { class: "p-field" }
const _hoisted_12 = { for: "threshold" }
const _hoisted_13 = { class: "p-field" }
const _hoisted_14 = { for: "gap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.modelValue,
    modal: true,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    closable: "",
    header: _ctx.$t('stock.details')
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', false))),
        label: _ctx.$t('close'),
        icon: "pi pi-times"
      }, null, 8, ["label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.product.image)
            ? (_openBlock(), _createBlock(_component_Image, {
                key: 0,
                src: _ctx.getImage(_ctx.product.image),
                height: 80,
                preview: ""
              }, null, 8, ["src"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("product.reference")), 1),
          _createVNode(_component_InputText, {
            disabled: "",
            "model-value": _ctx.product.reference,
            id: "reference"
          }, null, 8, ["model-value"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("product.name")), 1),
          _createVNode(_component_InputText, {
            disabled: "",
            "model-value": _ctx.product.name,
            id: "name"
          }, null, 8, ["model-value"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("product.category")), 1),
          _createVNode(_component_InputText, {
            disabled: "",
            "model-value": _ctx.product.category.label,
            id: "category"
          }, null, 8, ["model-value"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("stock.stock")), 1),
          _createVNode(_component_InputNumber, {
            "model-value": _ctx.resume.stock,
            locale: _ctx.numberSeparators[_ctx.activeActivity.separator],
            "max-fraction-digits": 20,
            disabled: "",
            id: "quantity",
            mode: "decimal"
          }, null, 8, ["model-value", "locale"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("product.threshold")), 1),
          _createVNode(_component_InputNumber, {
            "model-value": _ctx.product.threshold,
            locale: _ctx.numberSeparators[_ctx.activeActivity.separator],
            "max-fraction-digits": 20,
            disabled: "",
            id: "threshold",
            mode: "decimal"
          }, null, 8, ["model-value", "locale"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("stock.gap")), 1),
          _createVNode(_component_InputNumber, {
            "model-value": _ctx.resume.gap,
            locale: _ctx.numberSeparators[_ctx.activeActivity.separator],
            "max-fraction-digits": 20,
            disabled: "",
            id: "gap",
            mode: "decimal"
          }, null, 8, ["model-value", "locale"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}