
import { computed, defineComponent } from "vue";
import { getImage, numberSeparators, sumStock } from "@/graphql/utils/utils";
import { activeActivity } from "@/plugins/i18n";

export default defineComponent({
  name: "PosDetailStock",
  props: ["product", "modelValue"],
  setup(props) {
    const resume = computed(() => {
      const stock = sumStock(props.product.stocks);
      return {
        stock,
        gap: stock - props.product.threshold,
      };
    });
    return {
      numberSeparators,
      activeActivity,
      resume,
      getImage,
    };
  },
});
